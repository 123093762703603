<template>
  <v-menu v-model="showFilters" transition="slide-y-transition" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        color="primary"
        title="Фильтры"
        v-bind="attrs"
        style="min-width: 0"
        icon
        v-on="on"
      >
        <v-icon>mdi-filter-menu-outline</v-icon>
      </v-btn>
    </template>
    <v-card max-height="400">
      <v-list class="py-0" dense>
        <template v-for="({ id, name }, i) in filterList">
          <v-list-item :key="`item-${id}`" class="pl-3">
            <v-list-item-content>
              <v-checkbox
                class="mt-0 pt-0"
                color="primary"
                :value="switchersModel[id]"
                :ripple="false"
                :label="name"
                hide-details
                dense
                @click="switchersModel = id"
              />
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="i !== filterList?.length - 1"
            :key="`divider-${id}-${i}`"
          />
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'DropdownFilter',
  props: {
    filterList: {
      type: Array,
      default: null,
    },
    switchers: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showFilters: false,
  }),
  computed: {
    switchersModel: {
      get() {
        return this.switchers
      },
      set(id) {
        if (!id) return

        this.$emit('update:switchers', {
          ...this.switchers,
          [id]: !this.switchers[id],
        })
      },
    },
  },
}
</script>
