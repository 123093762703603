export default [
  { text: 'Наименование набора', value: 'name' },
  {
    text: 'Кол-во требований, параметров',
    value: 'count',
    sortable: false,
    width: '90px',
  },
  { text: 'Привязан', value: 'project', sortable: false },
  { text: 'Тип', value: 'access_type', sortable: false },
  { text: 'Статус', value: 'status', sortable: false },
  { text: 'Версия', value: 'version', sortable: false },
  {
    text: 'Фильтр',
    value: 'filter',
    width: '90px',
    sortable: false,
  },
  { text: 'Последнее изменение', value: 'updated_at' },
  {
    text: '',
    value: 'actions',
    sortable: false,
  },
]
