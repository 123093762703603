<template>
  <v-dialog
    v-model="dialog"
    width="476"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />
        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            Публикация набора
            <v-spacer />
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-3">
            <v-text-field
              v-model.trim="fields.version"
              label="Версия*"
              :rules="[rules.required]"
              outlined
              dense
            />
            <v-textarea
              v-model.trim="fields.comment"
              label="Комментарий"
              outlined
              dense
            />
            <div class="mt-6">*&nbsp;&mdash; обязательно</div>
          </v-card-text>
          <SaveCloseButtons
            :disabled="isLoading"
            btn-text-save="Опубликовать"
            @close="dialog = false"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import SaveCloseButtons from '@/components/SaveCloseButtons'
import rules from '@/mixins/rules'

export default {
  name: 'ModalPublishingASet',
  components: { SaveCloseButtons },
  mixins: [rules],
  props: {
    afterPublication: {
      type: Function,
      default: () => null,
    },
    argsAfterFn: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: false,
    fields: {
      version: '',
      comment: '',
    },
    setId: '',
  }),
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    ...mapActions('eirSets', ['publishEirSet']),
    show(id) {
      this.setId = id
      this.dialog = true
    },
    async submit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.isLoading = true

        await this.publishEirSet({
          id: this.setId,
          ...this.fields,
        })

        if (this.afterPublication) {
          await this.afterPublication(...this.argsAfterFn)
        }

        this.isLoading = false
        this.dialog = false
      }
    },
  },
}
</script>
