export const rulesObject = {
  required: (v) => (v !== '' && v !== null) || 'Обязательно к заполнению',
  password: (v) => v.length >= 6 || 'Не менее 6 символов',
  email: (v) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(v) || 'Некорректный электронный адрес'
  },
  guid: (v) => {
    const pattern =
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
    return pattern.test(v) || 'Некорректный GUID'
  },
}

export default { data: () => ({ rules: rulesObject }) }
