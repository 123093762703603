<template>
  <v-card-actions>
    <v-btn
      class="flex"
      elevation="0"
      :disabled="disabled"
      x-large
      @click="$emit('close')"
    >
      {{ btnTextClose }}
    </v-btn>

    <v-btn
      v-if="!disableBtnSave"
      class="flex"
      color="primary"
      type="submit"
      elevation="0"
      x-large
      :disabled="disabled || disabledBtnSave"
      @click="$emit(formMode)"
    >
      {{ formMode === 'create' ? btnTextCreate || btnTextSave : btnTextSave }}
    </v-btn>

    <slot />
  </v-card-actions>
</template>

<script>
export default {
  name: 'SaveCloseButtons',

  props: {
    btnTextClose: {
      type: String,
      default: 'Отменить',
    },
    formMode: {
      type: String,
      default: 'create',
      validator: (prop) => ['create', 'update'].includes(prop),
    },
    btnTextSave: {
      type: String,
      default: 'Сохранить',
    },
    btnTextCreate: {
      type: String,
      default: null,
    },
    disableBtnSave: Boolean,
    disabledBtnSave: Boolean,
    disabled: Boolean,
  },
}
</script>
