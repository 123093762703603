<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    content-class="guide_step_3-1"
    width="476"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            {{
              formMode === 'create'
                ? 'Добавление набора'
                : 'Редактирование набора'
            }}
          </v-card-title>

          <v-card-text class="pa-3">
            <v-text-field
              v-model.trim="fields.name"
              label="Наименование*"
              :rules="[rules.required]"
              outlined
              dense
            />

            <v-textarea
              v-model.trim="fields.description"
              label="Описание"
              outlined
              dense
            />

            <v-radio-group
              v-if="isAdmin"
              v-model="fields.access_type"
              label="Видимость набора*"
              :rules="[rules.required]"
              dense
            >
              <div class="d-flex">
                <v-radio class="mb-0 mr-4" label="Приватный" value="private" />
                <v-radio label="Публичный" value="public" />
              </div>
            </v-radio-group>

            <div class="mt-6">*&nbsp;&mdash; обязательно</div>
          </v-card-text>

          <SaveCloseButtons
            :form-mode="formMode"
            :disabled="isLoading"
            @close="dialog = false"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SaveCloseButtons from '@/components/SaveCloseButtons'
import rules from '@/mixins/rules'

export default {
  name: 'ModalEirSet',
  components: { SaveCloseButtons },
  mixins: [rules],
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: false,
    formMode: 'create',
    fields: {
      name: '',
      description: '',
      access_type: 'private',
    },
  }),
  computed: {
    ...mapGetters(['isAdmin']),
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    ...mapActions('eirSets', [
      'fetchEirSets',
      'createEirSet',
      'updateEirSet',
      'fetchSet',
    ]),
    show(data = {}, formMode = 'create') {
      this.formMode = formMode

      if (formMode === 'update') this.fields = { ...data }

      this.dialog = true
    },
    async submit() {
      this.$refs.form.validate()
      if (!this.valid) return

      const creation = this.formMode === 'create'
      const logMessage = creation
        ? 'Добавлен новый набор требований'
        : 'Изменён набор требований'

      this.isLoading = true

      if (creation) await this.createEirSet(this.fields)
      else await this.updateEirSet(this.fields)

      await this.$logActionEvent(logMessage) // набор требований

      const setId = +this.$route.params.setId

      if (!isNaN(setId)) await this.fetchSet(setId)
      else this.$emit('update-list')

      this.isLoading = false
      this.dialog = false
    },
  },
}
</script>
