<template>
  <span>
    <v-tooltip v-bind="tooltipProps">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...attrs, ...btnProps }"
          :loading="isLoading"
          icon
          x-small
          v-on="on"
          @click.stop="copyAction"
        >
          <v-icon v-bind="iconProps">mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>{{ isLoading ? 'Копирование набора...' : 'Копировать' }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CopySetButton',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    tooltipProps: {
      type: Object,
      default: () => ({
        left: true,
      }),
    },
    btnProps: {
      type: Object,
      default: () => ({}),
    },
    iconProps: {
      type: Object,
      default: () => ({
        size: 17,
      }),
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    ...mapActions('eirSets', ['copyEirSet']),
    async copyAction() {
      if (this.isLoading) return
      if (confirm(`Создать копию набора «${this.name}»?`)) {
        this.isLoading = true
        const { data } = await this.copyEirSet({
          id: this.id,
          name: `Копия «${this.name}»`,
        })
        this.isLoading = false
        await this.$router.push(`/eir-sets/${data.id}`)
      }
    },
  },
}
</script>
