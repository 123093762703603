<template>
  <div>
    <div class="d-flex mb-2 align-center">
      <h1 class="mr-2">{{ name }}</h1>
      <DropdownFilter :filter-list="filterList" :switchers.sync="switchers" />
      <div class="mx-6">
        <v-autocomplete
          v-model="selectedTagsModel"
          label="Фильтрация по тегам"
          :items="tagList"
          :loading="isTagLoading"
          item-value="id"
          item-text="name"
          prepend-inner-icon="mdi-tag-multiple-outline"
          multiple
          hide-details
          chips
          small-chips
          deletable-chips
          outlined
          dense
          clearable
        >
          <template #item="{ item: tag, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-simple-checkbox
                  :value="selectedTags.includes(tag.id)"
                  :ripple="false"
                  v-on="on"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ tag.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  x-small
                  title="Удалить тег"
                  icon
                  class="elevation-0"
                  @click.native.stop="removeTag(tag.id)"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <v-btn
        v-if="isAdmin || isManager"
        class="ml-auto"
        large
        color="primary"
        elevation="10"
        @click="$refs.modal.show()"
      >
        <v-icon class="mr-1" size="18">mdi-plus</v-icon>
        Создать
      </v-btn>
    </div>

    <v-data-table
      class="custom-table with-actions"
      loading-text="Загрузка... Пожалуйста, подождите"
      :loading="isLoading"
      :headers="TABLE_HEADERS"
      :items="eirSets"
      :items-per-page="20"
      :hide-default-footer="eirSetsTotal <= 20"
      :server-items-length="eirSetsTotal"
      :options.sync="tableOptions"
      :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
      :mobile-breakpoint="0"
      sort-desc
      @click:row="(row) => $router.push(`/eir-sets/${row.id}`)"
    >
      <template #item.name="{ item }">
        <div class="d-flex align-center">
          <div class="mr-2">
            <div :title="item.name">
              {{ item.name | truncate(80) }}
            </div>
            <div class="d-flex align-center">
              <div v-if="item.tags?.length" class="pr-1">
                <v-chip
                  v-for="tag in item.tags"
                  :key="`${tag.id}-${tag.slug}`"
                  x-small
                  color="primary"
                  class="mr-1"
                  close
                  @click.native.stop
                  @click:close="unlinkTagsFromSet(item.id, tag.id)"
                >
                  {{ tag.name }}
                </v-chip>
              </div>
              <div>
                <v-menu
                  v-model="showTagsMenu[item.id]"
                  transition="slide-y-transition"
                  bottom
                  open-on-click
                  :close-on-click="false"
                  :close-on-content-click="false"
                  @close="resetTagsMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      fab
                      color="primary"
                      class="elevation-0 small"
                      v-on="on"
                      @click.native.stop
                    >
                      <v-icon x-small>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-card max-height="500">
                    <v-progress-linear
                      v-if="isTagLoading"
                      color="primary"
                      indeterminate
                    />

                    <v-card-title class="body-1 font-weight-bold">
                      Привязка тегов к набору
                      <v-spacer />
                      <v-btn small icon @click="closeTagsMenu(item.id)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div class="d-flex align-center">
                        <div>
                          <v-autocomplete
                            v-model="selectedTagsMenu"
                            label="Теги"
                            :loading="isTagLoading"
                            :items="
                              tagList?.filter(
                                (tag) =>
                                  item.tags?.findIndex(
                                    ({ id }) => id === tag.id
                                  ) === -1
                              )
                            "
                            item-value="id"
                            item-text="name"
                            prepend-inner-icon="mdi-tag-multiple-outline"
                            multiple
                            hide-details
                            chips
                            small-chips
                            deletable-chips
                            outlined
                            dense
                            clearable
                            append-icon="mdi-link"
                            :append-outer-icon="
                              !showAddTagInput ? 'mdi-plus' : ''
                            "
                            @click:append-outer="() => (showAddTagInput = true)"
                            @click:append="() => linkTagsToSet(item.id)"
                          >
                            <template #item="{ item: tag, on, attrs }">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-action>
                                  <v-simple-checkbox
                                    :value="selectedTagsMenu.includes(tag.id)"
                                    :ripple="false"
                                    v-on="on"
                                  />
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ tag.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    x-small
                                    icon
                                    title="Удалить тег"
                                    class="elevation-0"
                                    @click.native.stop="removeTag(tag.id)"
                                  >
                                    <v-icon color="error">
                                      mdi-trash-can-outline
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </div>
                        <div
                          v-if="showAddTagInput"
                          class="pl-2 d-flex align-center"
                        >
                          <v-text-field
                            v-model.trim="tagName"
                            label="Название тега"
                            :rules="[(v) => !!v || 'Обязательно к заполнению']"
                            outlined
                            hide-details
                            clearable
                            :disabled="isTagLoading"
                            dense
                          />
                          <v-btn
                            color="primary"
                            class="ml-2"
                            large
                            elevation="10"
                            :disabled="isTagLoading"
                            @click="addNewTag"
                          >
                            Создать
                          </v-btn>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </div>

          <v-menu
            v-if="item.description"
            v-model="showDescription[item.id]"
            open-on-hover
            transition="slide-y-transition"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="primary"
                class="ml-auto mr-2"
                v-bind="attrs"
                style="min-width: 0"
                icon
                v-on="on"
              >
                <v-icon>mdi-information-outline</v-icon>
                <v-icon small>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-card max-height="600" max-width="600">
              <v-card-title>Описание</v-card-title>
              <v-card-text>
                {{ item.description }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template #item.count="{ item }">
        <div class="mb-1">
          <v-icon size="20" class="mr-1">mdi-table-alert</v-icon>
          <span>{{ item.eir_element_count }}</span>
        </div>
        <div>
          <v-icon size="20" class="mr-1">mdi-cube-scan</v-icon>
          <span>{{ item.parameter_count }}</span>
        </div>
      </template>

      <template #item.access_type="{ item }">
        <div>
          {{ item.access_type === 'private' ? 'Личный' : 'Публичный' }}
        </div>
      </template>

      <template #item.project="{ item: { project } }">
        <a
          v-if="project && project[0]"
          :title="project[0].name"
          :href="`/projects/${project[0].id}`"
          @click.stop.prevent="$router.push(`/projects/${project[0].id}`)"
        >
          {{ project[0].name | truncate(50) }}
        </a>
        <span v-else>&mdash;</span>
      </template>

      <template #item.status="{ item: { versions } }">
        <v-chip
          v-if="checkIsPublished(versions)"
          small
          label
          outlined
          color="primary"
          class="mr-1"
        >
          Опубликован
        </v-chip>
        <v-chip v-else small label outlined class="mr-1">Черновик</v-chip>
      </template>

      <template #item.updated_at="{ item }">
        {{ item.updated_at | formatDate }}
      </template>

      <template #item.version="{ item: { id: setId, versions } }">
        <v-menu
          v-if="versions?.[0]?.version"
          v-model="showVersions[setId]"
          open-on-hover
          transition="slide-y-transition"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex">
              <v-chip
                v-bind="attrs"
                small
                label
                outlined
                color="primary"
                class="mr-1"
                v-on="on"
                @click.native.stop
              >
                {{ versions[0].version }}
              </v-chip>
              <v-icon color="primary" small>mdi-menu-down</v-icon>
            </div>
          </template>

          <v-card max-height="400">
            <v-list dense>
              <template
                v-for="(
                  { id: versionId, version, updated_at: updatedAt, comment },
                  index
                ) in versions"
              >
                <v-divider v-if="index % 2 !== 0" :key="index" inset />
                <v-list-item
                  :key="`version-${versionId}`"
                  :title="version"
                  :to="
                    index + 1 !== versions.length
                      ? `/eir-sets/${setId}/versions/${versionId}`
                      : null
                  "
                  append
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ version | truncate(40) }}&nbsp;
                      <span class="caption">
                        ({{ updatedAt | formatDate }})
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="caption" :title="comment">
                        {{ comment | truncate(40) }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-menu>
      </template>

      <template #item.filter="{ item }">
        <v-icon
          :color="item.check_eir_filters ? 'primary' : 'error'"
          :title="
            item.check_eir_filters
              ? 'Все фильтры назначены'
              : 'Есть требования без фильтров'
          "
        >
          {{
            item.check_eir_filters
              ? 'mdi-filter-check-outline'
              : 'mdi-filter-remove-outline'
          }}
        </v-icon>
      </template>

      <template #item.updated_at="{ item }">
        {{ item.updated_at | formatDate }}
      </template>

      <template #item.actions="{ item }">
        <div class="lots-of-action d-flex flex-row">
          <v-tooltip v-if="!checkIsPublished(item.versions)" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mr-2"
                size="17"
                :disabled="!item.check_eir_filters"
                v-on="on"
                @click.native.stop="$refs.modalPublishingASet.show(item.id)"
              >
                mdi-publish
              </v-icon>
            </template>
            <span>Опубликовать</span>
          </v-tooltip>

          <CopySetButton :id="item.id" class="mr-2" :name="item.name" />

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                size="17"
                v-bind="attrs"
                v-on="on"
                @click.stop="$refs.modal.show(item, 'update')"
              >
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Редактировать</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="18"
                v-bind="attrs"
                @click.stop="removeItem(item)"
                v-on="on"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <ModalEirSet ref="modal" @update-list="updateList" />
    <ModalPublishingASet
      ref="modalPublishingASet"
      :after-publication="updateList"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import debounce from 'lodash/debounce'

import { TABLE_HEADERS } from './constants'

import ModalEirSet from './partials/ModalEirSet'
import CopySetButton from '@/components/CopySetButton'
import ModalPublishingASet from '@/components/ModalPublishingASet'
import DropdownFilter from '@/components/DropdownFilter'

export default {
  name: 'EirSets',
  components: {
    ModalPublishingASet,
    CopySetButton,
    ModalEirSet,
    DropdownFilter,
  },
  data: () => ({
    isLoading: true,
    switchers: {
      isPrivate: false,
      unattached: false,
    },
    filterList: [
      { name: 'Только мои требования', id: 'isPrivate' },
      { name: 'Без проекта', id: 'unattached' },
    ],
    showDescription: {},
    TABLE_HEADERS,
    tableOptions: {},
    showVersions: {},
    /**
     * Tags
     * */
    isTagLoading: false,
    showTagsMenu: {},
    selectedTagsMenu: [],
    showAddTagInput: false,
    tagName: null,
  }),
  computed: {
    ...mapGetters(['isAdmin', 'isManager']),
    ...mapState('eirSets', ['eirSets', 'eirSetsTotal', 'eirSetsOptions']),
    ...mapState('tags', ['tagList', 'selectedTags']),
    name: (vm) => vm.$route.name,
    selectedTagsModel: {
      get() {
        return this.selectedTags
      },
      set(value = []) {
        this.setSelectedTags(value)
      },
    },
  },
  watch: {
    tableOptions() {
      this.fetchSets()
    },
    'switchers.unattached'() {
      this.fetchSets()
    },
    'switchers.isPrivate'() {
      this.fetchSets()
    },
    selectedTags() {
      this.debounceFetch()
    },
  },
  mounted() {
    this.startToFetch()
  },
  methods: {
    ...mapActions('eirSets', ['fetchEirSets', 'deleteEirSet']),
    ...mapActions('tags', [
      'fetchTags',
      'addTag',
      'deleteTag',
      'linkTags',
      'unlinkTags',
    ]),
    ...mapMutations('tags', ['setSelectedTags']),
    checkIsPublished(versions = []) {
      const last = versions?.[0] ?? null

      return last && last?.status === 'published'
    },
    updateList() {
      setTimeout(this.fetchSets, 1000)
    },
    async fetchSets() {
      this.isLoading = true

      let filters = {}

      if (this.switchers.isPrivate) filters.access_type = 'private'
      if (this.switchers.unattached)
        filters.unattached = this.switchers.unattached
      if (this.selectedTags?.length)
        filters.tags__in = this.selectedTags.join(',')

      await this.fetchEirSets({
        options: this.tableOptions,
        filters,
      })

      this.isLoading = false
    },
    async removeItem({ id }) {
      if (!confirm('Удалить набор информационных требований?')) return

      this.isLoading = true

      await this.deleteEirSet(id)
      await this.$logActionEvent('Набор информационных требований удален')

      this.updateList()

      this.isLoading = false
    },
    debounceFetch: debounce(async function () {
      await this.fetchSets()
    }, 600),
    /**
     * Tags
     * */
    async startToFetch() {
      try {
        this.isTagLoading = true
        await this.fetchTags()
      } finally {
        this.isTagLoading = false
      }
    },
    async linkTagsToSet(setId) {
      if (!this.selectedTagsMenu?.length) return

      const selected = this.selectedTagsMenu.map((id) => ({ id }))

      this.showTagsMenu[setId] = false
      this.resetTagsMenu()

      try {
        this.isTagLoading = true
        const success = await this.linkTags({
          setId: setId,
          tagIds: selected,
        })
        if (success) await this.debounceFetch()
      } finally {
        this.isTagLoading = false
      }
    },
    async unlinkTagsFromSet(setId, tagId) {
      try {
        this.isTagLoading = true
        const success = await this.unlinkTags({
          setId: setId,
          tagIds: [{ id: tagId }],
        })
        if (success) await this.debounceFetch()
      } finally {
        this.isTagLoading = false
      }
    },
    async addNewTag() {
      if (!this.tagName) return

      try {
        this.isTagLoading = true
        this.showAddTagInput = false
        await this.addTag({ name: this.tagName })
      } finally {
        this.tagName = null
        this.isTagLoading = false
      }
    },
    async removeTag(tagId) {
      if (!confirm('Удалить тег?')) return

      try {
        this.isTagLoading = true
        await this.deleteTag(tagId)
        await this.debounceFetch()
      } finally {
        this.isTagLoading = false
      }
    },
    closeTagsMenu(id) {
      this.showTagsMenu[id] = false
      this.resetTagsMenu()
    },
    resetTagsMenu() {
      this.selectedTagsMenu = []
      this.showAddTagInput = false
      this.tagName = null
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table.with-actions {
  :deep(td:last-child),
  :deep(th:last-child) {
    width: 0;
    padding: 0;
  }
}

.small {
  height: 18px;
  width: 18px;
}
</style>
